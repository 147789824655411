export const stringHelpers = {
  clearPhone: (val: string) => {
    if (!val) return "";
    return val.replace(/\D+/g, "");
  },

  clearString: (val: string) => {
    if (!val) return "";
    return val.replace(/\D+/g, "");
  },

  transformMoneyValue: (val: string | number | undefined | null, div = ",") => {
    if (!val) return "";

    let newString = String(val);
    const indexOfDots = newString.indexOf(".");
    let tail = "";

    if (indexOfDots != -1) {
      tail = div + newString.substring(indexOfDots + 1);
      newString = newString.substring(0, indexOfDots);
    }

    let temp: Array<string> = [];
    let array = Array.from(newString);
    let iterator = 0;

    for (let i = array.length - 1; i >= 0; i--) {
      if (iterator % 3 == 0) {
        temp.push(" ");
      }
      temp.push(array[i]);
      iterator++;
    }

    return temp.reverse().join("").trim() + tail;
  },

  phoneNormalize: (phone: string | null) => {
    if (typeof phone === "string") {
      if (phone.includes("+")) return phone;
      return `+${phone?.slice(0, 1)} ${phone?.slice(1, 4)} ${phone?.slice(4, 7)} ${phone?.slice(7, 9)} ${phone?.slice(9)}`;
    } else {
      return "-";
    }
  },

  formatDivisionCode: (code: string) => {
    if (code.includes("-")) {
      return code;
    } else {
      return code.slice(0, 3) + "-" + code.slice(3, 6);
    }
  }
};

export const onPastePhoneNumberHandler = (e: any) => {
  const value: string = e.clipboardData.getData("text/plain");

  if (value) {
    const symbolsToDelete = [" "];
    const formattedValue = Array.from(value)
      .filter(
        (char) => !symbolsToDelete.includes(char) && !Number.isNaN(Number(char))
      )
      .join("");
    if (formattedValue.length == 11) {
      e.target.value = formattedValue.substring(1);
    }
  }
};

export const firstLetterUpperCase = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const stringToNumber = (string: any): number => {
  if (!string) return 0;

  if (typeof string === "number") {
    return string;
  } else {
    const spaceIdx = string.split("").findIndex((item: string, idx: number) => {
      return string.charCodeAt(idx) === 32 || string.charCodeAt(idx) === 160;
    });
    return Number(string.split(string[spaceIdx]).join(""));
  }
};

export const compareCyrLatSrtings = (str1: string, str2: string): boolean => {
  if (str1 === str2) {
    return true;
  } else {
    const convertedStr = [];
    const characters: {
      [key: string]: string;
    } = {
      // keys - кириллица, values - латиница
      А: "A",
      В: "B",
      С: "C",
      Е: "E",
      Н: "H",
      К: "K",
      М: "M",
      О: "O",
      Р: "P",
      Т: "T",
      Х: "X",
      У: "Y",
    };

    const keys: Array<string> = Object.keys(characters);
    const values: Array<string> = Object.values(characters);

    if (/[А-Я]/i.test(str2)) {
      for (let i = 0; i < str2?.length; i++) {
        if (keys.join("").includes(str2[i])) {
          for (let j = 0; j < keys.length; j++) {
            if (str2[i] === keys[j]) {
              convertedStr.push(values[j]);
            }
          }
        } else {
          convertedStr.push(str2[i]);
        }
      }
      return str1 === convertedStr.join("");
    } else {
      for (let i = 0; i < str2?.length; i++) {
        if (values.join("").includes(str2[i])) {
          for (let j = 0; j < values.length; j++) {
            if (str2[i] === values[j]) {
              convertedStr.push(keys[j]);
            }
          }
        } else {
          convertedStr.push(str2[i]);
        }
      }
      return str1 === convertedStr.join("");
    }
  }
};

export const compareDriverLicenseCategories = (str1: string, str2: string) => {
  if (str1 && str2) {
    if (str1 === str2) {
      return true;
    } else {
      return compareCyrLatSrtings(
        str1.indexOf("/") > -1 ? str1.substring(0, str1.indexOf("/")) : str1,
        str2.indexOf("/") > -1 ? str2.substring(0, str2.indexOf("/")) : str2
      );
    }
  }
};
