import { getDivisionCodeType } from "../application/types";
import { mainAxios } from "../index";
import { dataToQueryString } from "../../methods/dataToQueryString";
import {
  daDataBaseResponseType,
  // getAddressType,
  getCarType,
  getCompanyType,
  getInTransactionType,
  getLoanType,
  getOutTransactionType,
  getScheduleType,
} from "./types";
import { AxiosResponse } from "axios";

export const servicesApi = {
  // getDivisionCode: ({ code }: getDivisionCodeType) =>
  //   mainAxios.get(`/passport/division_code/${code}`),
  //
  // getLoan: (query: getLoanType) =>
  //   mainAxios.get(`/loan${dataToQueryString(query)}`),
  //
  // getOutTransaction: (query: getOutTransactionType) =>
  //   mainAxios.get(`/out-transaction${dataToQueryString(query)}`),
  //
  // getInTransaction: (query: getInTransactionType) =>
  //   mainAxios.get(`/in-transaction${dataToQueryString(query)}`),

  // getCar: (query: getCarType) =>
  //   mainAxios.get(`/car${dataToQueryString(query)}`),

  getAddress: ({ address }: { address: string }) =>
    mainAxios.get<{ data: daDataBaseResponseType }>(`/address?search=${address}`),

  getBank: ({ bic }: { bic: `${number}` }) =>
    mainAxios.get<{ data: daDataBaseResponseType }>(`/bank?search=${bic}`),

  getCompany: ({ inn }: { inn: `${number}` }) =>
    mainAxios.get<{ data: daDataBaseResponseType }>(`/company?search=${inn}`),

  getSchedule: (query: getScheduleType): Promise<AxiosResponse<any>> =>
    mainAxios.get(`/schedule${dataToQueryString(query)}`),
};
