import { ImageFactory } from "../../helpers/imageFactory";

//logo
import { ReactComponent as Logo } from "./logo/logo.svg";
import { ReactComponent as LogoSmall } from "./logo/logoSmall.svg";

//main
import Exit from "./main/Exit.svg";
import Triangle from "./main/triangle.svg";
// import Pencil from "./main/pencil.svg";

//ui
import { ReactComponent as ArrowBlack } from "./ui/arrowBlack.svg";
import { ReactComponent as CheckmarkPurple } from "./ui/checkmarkPurple.svg";
import { ReactComponent as CheckmarkPurpleBg } from "./ui/checkmark_purple_bg.svg";
import { ReactComponent as CheckmarkPurpleSmall } from "./ui/checkmarkPurpleSmall.svg";
import { ReactComponent as CheckmarkInCircle } from "./ui/checkmarkInCircle.svg";
import { ReactComponent as Checkmark } from "./ui/checkmark.svg";
import { ReactComponent as CrossWarning } from "./ui/cross_warning.svg";
import { ReactComponent as DownloadWhiteGrayBg } from "./ui/downloadWhiteGrayBg.svg";
import { ReactComponent as Mirror } from "./ui/mirror.svg";
import { ReactComponent as Spread } from "./ui/spread.svg";
import { ReactComponent as TurnLeft } from "./ui/turn_left.svg";
import { ReactComponent as TurnRight } from "./ui/turn_right.svg";
import { ReactComponent as ZoomX2 } from "./ui/zoom_x2.svg";
import { ReactComponent as ZoomX3 } from "./ui/zoom_x3.svg";
import { ReactComponent as ArrowDropdown } from "./ui/arrowDropdown.svg";
import Lock from "./ui/lock.svg";
import Pencil from "./ui/Pencil.svg";
import { ReactComponent as PencilNewPhone } from "./ui/PencilNewPhone.svg";
import PencilBlack from "./ui/pencilBlack.svg";
import RubBlack from "./ui/rub_black.svg";
import Percent from "./ui/percent.svg";
import { ReactComponent as Loader } from "./ui/loader.svg";
import arrowAngleIcon from "./ui/ArrowAngle.svg";
import Close from "./ui/close.svg";
import CloseWhite from "./ui/closeWhite.svg";
import CloseWhiteThin from "./ui/closeWhiteThin.svg";
import Date from "./ui/date.svg";
import ArrowIcon from "./ui/Arrow.svg";
import { ReactComponent as Calendar } from "./ui/calendar.svg";
import Document from "./ui/document.svg";
import { ReactComponent as DownloadWhite } from "./ui/DownloadWhite.svg";
import DownloadBlue from "./ui/DownloadBlue.svg";
import DoubleArrow from "./ui/DoubleArrow.svg";
import Approved from "./ui/approved.svg";
import { ReactComponent as IconForElpts } from "./ui/IconForElpts.svg";
// import Approve from "./ui/checkmarkPurple.svg";
import Checkbox_checked from "./ui/checkbox_checked.svg";
import Checkbox_unchecked from "./ui/checkbox_unchecked.svg";
import NoMoney from "./ui/noMoney.svg";
import NoPhoneAnswer from "./ui/noPhoneAnswer.svg";
import closeWhiteModal from "./ui/closeWhiteModal.svg";
import Refused from "./ui/refused.svg";
import { ReactComponent as LoaderButton } from "./ui/LoaderButton.svg";
import { ReactComponent as PencilWhite } from "./ui/pencilWhite.svg";
import { ReactComponent as Rub } from "./ui/rub.svg";
import Danger from "./ui/danger.svg";

//switcher
import ApprovalSwitcher from "./Switcher/uprove.svg";
import ApprovalSwitcherGray from "./Switcher/ApprovalSwitcherGray.svg";
import ApprovalSwitcherWhite from "./Switcher/ApprovalSwitcherWhite.svg";
import CloseSwitcherGray from "./Switcher/CloseSwitcherGray.svg";
import CloseSwitcherWhite from "./Switcher/Close.svg";
import CloseSwitcher from "./Switcher/CloseSwitcher.svg";
import NeutralSwitchGray from "./Switcher/NeutralSwitchGray.svg";
import NeutralSwitchOrange from "./Switcher/NeutralSwitchOrange.svg";
import NeutralSwitchWhite from "./Switcher/NeutralSwitchWhite.svg";

export const Icons = {
  logo: {
    Logo,
    LogoSmall,
  },
  main: {
    Exit: ImageFactory(Exit, "Exit"),
    Triangle: ImageFactory(Triangle, "Triangle"),
    pencilL: ImageFactory(Pencil, "Pencil"),
  },
  ui: {
    IconForElpts,
    ArrowBlack,
    Calendar,
    Checkmark,
    CheckmarkInCircle,
    CheckmarkPurple,
    CheckmarkPurpleBg,
    CheckmarkPurpleSmall,
    CrossWarning,
    DownloadWhite,
    DownloadWhiteGrayBg,
    ArrowDropdown,
    Mirror,
    Spread,
    TurnLeft,
    TurnRight,
    ZoomX2,
    ZoomX3,
    Lock: ImageFactory(Lock, "Lock"),
    Pencil: ImageFactory(Pencil, "Pencil"),
    PencilNewPhone,
    PencilWhite,
    PencilBlack: ImageFactory(PencilBlack, "PencilBlack"),
    Rub,
    RubBlack: ImageFactory(RubBlack, "RubBlack"),
    Percent: ImageFactory(Percent, "Percent"),
    arrowAngleIcon: ImageFactory(arrowAngleIcon, "arrowAngleIcon"),
    Close: ImageFactory(Close, "Close"),
    CloseWhite: ImageFactory(CloseWhite, "CloseWhite"),
    CloseWhiteThin: ImageFactory(CloseWhiteThin, "CloseWhiteThin"),
    Date: ImageFactory(Date, "Date"),
    arrowIcon: ImageFactory(ArrowIcon, "arrowIcon"),
    // Calendar: ImageFactory(Calendar, "Calendar"),
    Document: ImageFactory(Document, "Document"),
    // Approve: ImageFactory(Approve, "Approve"),
    NoMoney: ImageFactory(NoMoney, "NoMoney"),
    NoPhoneAnswer: ImageFactory(NoPhoneAnswer, "NoPhoneAnswer"),
    closeWhiteModal: ImageFactory(closeWhiteModal, "closeWhiteModal"),
    DownloadBlue: ImageFactory(DownloadBlue, "DownloadBlue"),
    // DownloadWhite: ImageFactory(DownloadWhite, "DownloadWhite"),
    DoubleArrow: ImageFactory(DoubleArrow, "DoubleArrow"),
    Refused: ImageFactory(Refused, "Refused"),
    Approved: ImageFactory(Approved, "Approved"),
    Checkbox_checked: ImageFactory(Checkbox_checked, "Document"),
    Checkbox_unchecked: ImageFactory(Checkbox_unchecked, "Document"),
    Danger: ImageFactory(Danger, "Danger"),
  },
  loader: {
    Loader,
    LoaderButton,
  },
  switcher: {
    ApprovalSwitcher: ImageFactory(ApprovalSwitcher, "ApprovalSwitcher"),
    ApprovalSwitcherGray: ImageFactory(
      ApprovalSwitcherGray,
      "ApprovalSwitcherGray"
    ),
    ApprovalSwitcherWhite: ImageFactory(
      ApprovalSwitcherWhite,
      "ApprovalSwitcherWhite"
    ),
    CloseSwitcherGray: ImageFactory(CloseSwitcherGray, "CloseSwitcherGray"),
    CloseSwitcherWhite: ImageFactory(CloseSwitcherWhite, "CloseSwitcherWhite"),
    CloseSwitcher: ImageFactory(CloseSwitcher, "CloseSwitcher"),
    NeutralSwitchGray: ImageFactory(NeutralSwitchGray, "NeutralSwitchGray"),
    NeutralSwitchOrange: ImageFactory(
      NeutralSwitchOrange,
      "NeutralSwitchOrange"
    ),
    NeutralSwitchWhite: ImageFactory(NeutralSwitchWhite, "NeutralSwitchWhite"),
  },
};
